@font-face {
  font-family: 'TofinoPersonal-Medium';
  src: url('../../css/fonts/TofinoPersonal-Medium.eot?#iefix') format('embedded-opentype'), url('../../css/fonts/TofinoPersonal-Medium.otf') format('opentype'),
    url('../../css/fonts/TofinoPersonal-Medium.woff') format('woff'), url('../../css/fonts/TofinoPersonal-Medium.ttf') format('truetype'), url('../../css/fonts/TofinoPersonal-Medium.svg#TofinoPersonal-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'TofinoPersonal-Regular';
  src: url('../../css/fonts/TofinoPersonal-Regular.eot?#iefix') format('embedded-opentype'), url('../../css/fonts/TofinoPersonal-Regular.otf') format('opentype'),
    url('../../css/fonts/TofinoPersonal-Regular.woff') format('woff'), url('../../css/fonts/TofinoPersonal-Regular.ttf') format('truetype'), url('../../css/fonts/TofinoPersonal-Regular.svg#TofinoPersonal-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'TofinoPersonal-Book';
  src: url('../../css/fonts/TofinoPersonal-Book.eot?#iefix') format('embedded-opentype'), url('../../css/fonts/TofinoPersonal-Book.otf') format('opentype'),
    url('../../css/fonts/TofinoPersonal-Book.woff') format('woff'), url('../../css/fonts/TofinoPersonal-Book.ttf') format('truetype'), url('../../css/fonts/TofinoPersonal-Book.svg#TofinoPersonal-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gilmer-Heavy';
  src: url('../../css/fonts/Gilmer-Heavy.eot?#iefix') format('embedded-opentype'), url('../../css/fonts/Gilmer-Heavy.otf') format('opentype'),
    url('../../css/fonts/Gilmer-Heavy.woff') format('woff'), url('../../css/fonts/Gilmer-Heavy.ttf') format('truetype'), url('../../css/fonts/Gilmer-Heavy.svg#Gilmer-Heavy') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gilmer-Regular';
  src: url('../../css/fonts/Gilmer-Regular.eot?#iefix') format('embedded-opentype'), url('../../css/fonts/Gilmer-Regular.otf') format('opentype'),
    url('../../css/fonts/Gilmer-Regular.woff') format('woff'), url('../../css/fonts/Gilmer-Regular.ttf') format('truetype'), url('../../css/fonts/Gilmer-Regular.svg#Gilmer-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gilmer-Medium';
  src: url('../../css/fonts/Gilmer-Medium.eot?#iefix') format('embedded-opentype'), url('../../css/fonts/Gilmer-Medium.otf') format('opentype'),
    url('../../css/fonts/Gilmer-Medium.woff') format('woff'), url('../../css/fonts/Gilmer-Medium.ttf') format('truetype'), url('../../css/fonts/Gilmer-Medium.svg#Gilmer-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Gilmer-Bold';
  src: url('../../css/fonts/Gilmer-Bold.eot?#iefix') format('embedded-opentype'), url('../../css/fonts/Gilmer-Bold.otf') format('opentype'),
    url('../../css/fonts/Gilmer-Bold.woff') format('woff'), url('../../css/fonts/Gilmer-Bold.ttf') format('truetype'), url('../../css/fonts/Gilmer-Bold.svg#Gilmer-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
