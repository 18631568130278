.tag {
  border-radius: 4px 12px 12px 4px;
  padding: 3px 12px;
  border: 0;
  font-size: 11px;
  border: 0px;
  background-color: rgba(214, 217, 221, 0.54) !important;
  color: #b0b5bb !important;
}
.tag-xs {
  border-radius: 4px 12px 12px 4px;
  padding: 1px 8px;
  font-size: 10px;
  height: auto;
  min-width: 0px;
}
.tag-danger {
  background-color: rgba(229, 0, 0, 0.2) !important;
  color: rgb(229, 0, 0) !important;
}

.tag-primary {
  background-color: rgb(0 52 115 / 46%) !important;
  color: #003473 !important;
}
.tag-info {
  background-color: rgb(23 162 184 / 46%) !important;
  color: #17a2b8 !important;
}
.tag-secondary {
  background-color: rgb(8 33 84 / 75%) !important;
  color: #ffffff !important;
}
.tag-light {
  background-color: rgb(214 217 221 / 60%) !important;
  color: #748497 !important;
}
.tag-warning {
  background-color: rgb(255 213 0) !important;
  color: #949210 !important;
}
.tag-orange {
  background-color: #ff9c00b8 !important;
  color: #a26400 !important;
}
.tag-success {
  background-color: rgb(41 222 0 / 46%) !important;
  color: #009847 !important;
}
.txt-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}