.tree-items {
  .pdg {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mrg {
    padding-top: 6px;
    padding-bottom: 6px;
    margin-left: 15px;
    margin-right: 0px;
  }

  .request-side {
    display: flex;
    align-items: center;
  }

  .request-link-spec-styles {
    color: rgb(33, 37, 41);
    text-decoration: none;
  }
}
