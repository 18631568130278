/*==============================================================
 For all pages
 ============================================================== */

#main-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: relative;
}

.page-wrapper {
    // background: $body-bg;
    position: relative;
    transition: 0.2s ease-in;
    // display: none;
    box-shadow: $shadow;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.page-wrapper>.container-fluid {
    padding: 25px 35px;
    min-height: calc(100vh - 210px);
}

.custom-card {
    width: 100%;
    padding: 3% 2%;
    margin-top: 15px;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}


/*******************
 Footer
*******************/

.footer {
    padding: 15px 20px;
}


/*******************
 Stickey inner-left-part
*******************/
.left-part {
    height: 100%;
    width: $left-part-width;
    position: absolute;
    border-right: 1px solid $border-color;

    &.fixed-left-part {
        position: fixed;
        top: 0px;
        padding-top: 10px;
    }

    .show-left-part {
        position: absolute;
        top: 45%;
        right: -41px;
    }
}

.right-part {
    width: calc(100% - 260px);
    min-height: calc(100vh - 64px);
    overflow: auto;
    margin-left: $left-part-width;
}

.reverse-mode {
    .left-part {
        right: 0;
        border-left: 1px solid $border-color;
    }

    .show-left-part {
        right: auto;
        left: -41px;
    }

    .right-part {
        margin-left: 0px;
        margin-right: $left-part-width;
    }
}


/*******************
 Vertical layout
*******************/

#main-wrapper[data-layout="vertical"] {

    /*Vertical*/
    .topbar .top-navbar .navbar-header {
        //width: $sidebar-width-full;
        transition: 0.2s ease-in;
    }

    /*sidebar type*/
    &[data-sidebartype="full"] {
        .page-wrapper {
            margin-left: $sidebar-width-full;
        }
    }

    //    Iconbar Style is in iconbar.scss file

    /*Sidebar position*/
    &[data-sidebar-position="fixed"] {
        .topbar .top-navbar .navbar-header {
            position: fixed;
            z-index: 10;
        }

        .left-sidebar {
            position: fixed;
        }
    }

    /*Header position*/
    &[data-header-position="fixed"] {
        .topbar {
            position: fixed;
            width: 100%;
        }

        .page-wrapper {
            padding-top: $topbar-height;
        }
    }

    /*Boxed Layout*/
    &[data-boxed-layout="boxed"] {
        max-width: $boxed-width;
        margin: 0 auto;
        position: relative;
        box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
    }

    &[data-boxed-layout="boxed"][data-header-position="fixed"] {
        .topbar {
            max-width: $boxed-width;
        }
    }
}

/*******************
 Vertical responsive layout
*******************/

@include media-breakpoint-up(md) {
    #main-wrapper[data-layout="vertical"] {

        /*Vertical*/
        &[data-sidebartype="full"] {
            .topbar .top-navbar .navbar-header {
                width: $sidebar-width-full;
            }
        }

        /*Sidebar position*/
        &[data-sidebar-position="fixed"][data-sidebartype="full"] {
            .topbar .top-navbar .navbar-collapse {
                margin-left: $sidebar-width-full;
            }
        }

        &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
            .topbar .top-navbar .navbar-collapse {
                margin-left: $sidebar-width-mini;
            }
        }

        /*Vertical with mini-sidebar*/
        &[data-sidebartype="mini-sidebar"] {
            .topbar .top-navbar .navbar-header {
                width: $sidebar-width-mini;

                .navbar-brand {
                    padding: $minisidebar-topbar-navbrand-padding;
                }

                .logo-text {
                    display: none;
                }

                &.expand-logo {
                    width: $sidebar-width-full;

                    .logo-text,
                    .logo-icon {
                        display: inline-block;
                    }
                }
            }

            .page-wrapper {
                margin-left: $sidebar-width-mini;
            }

            .sidebar-nav {

                .hide-menu,
                .has-arrow:after {
                    display: none;
                }

                .nav-small-cap {
                    justify-content: center;
                }

                ul {
                    .list-divider {
                        margin: $list-divider-margin-minisidebar;
                    }

                    .sidebar-item {
                        .sidebar-link {
                            padding: $minisidebar-link-padding;
                            margin-right: 0;

                            .feather-icon,
                            i {
                                margin-right: 0;
                                margin-left: 7px;
                            }
                        }

                        .first-level {
                            .sidebar-item.active {
                                .sidebar-link {
                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .left-sidebar {
                width: $sidebar-width-mini;

                &:hover {
                    width: $sidebar-width-full;

                    .sidebar-nav {
                        ul {
                            .list-divider {
                                margin: $list-divider-margin;
                            }

                            .sidebar-item {
                                .sidebar-link {
                                    margin-right: 17px;

                                    .feather-icon,
                                    i {
                                        margin-right: 8px;
                                        margin-left: 0;
                                    }
                                }

                                .first-level {
                                    .sidebar-item.active {
                                        .sidebar-link {
                                            &::before {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .hide-menu,
                        .has-arrow:after {
                            display: block;
                        }

                        .nav-small-cap {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    #main-wrapper {
        &[data-sidebartype="mini-sidebar"] {
            .left-sidebar {
                left: -$sidebar-width-full;
            }
        }

        &.show-sidebar {
            .left-sidebar {
                left: 0;
            }
        }

        &[data-layout="vertical"][data-sidebar-position="fixed"] {
            .topbar .top-navbar {
                .navbar-collapse {
                    position: relative;
                    top: $topbar-height;
                }
            }
        }

        /*Header position*/
        &[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"] {
            .topbar .top-navbar {
                .navbar-collapse {
                    position: relative;
                    top: $topbar-height;
                    background: $white;
                }
            }
        }
    }

    .left-part {
        left: -$left-part-width;
        background: $body-bg;
        z-index: 1;
        position: fixed;
        transition: 0.1s ease-in;

        &.show-panel {
            left: 0px;
        }
    }

    .reverse-mode .left-part {
        right: -$left-part-width;
        left: auto;

        &.show-panel {
            right: 0px;
        }
    }

    .right-part {
        width: 100%;
        margin-left: 0px;
    }
}
