.auth-wrapper {
  min-height: 71vh;

  .auth-box {
    box-shadow: $shadow;
    width: 600px;
    margin: 4% 0;
    position: relative;

    .modal-bg-img {
      background-size: cover;
      background-position: center center;
      min-height: 300px;
    }
  }

  #recoverform {
    display: none;
  }
}

.bg-facebook {
  background-color: #3b5a9a;
}

.bg-twitter {
  background-color: #56adf2;
}
