#firm-box.input {
  margin: 0 !important;

  &--isFocused {
    border: 1px solid var(--primary) !important;
  }
  &--isFilled {
    border: 1px solid var(--red) !important;
  }
  &--isError {
    border: 1px solid var(--red) !important;
  }
  &--isDisabled {
    border: 1px solid var(--gray) !important;
  }
  &__span {
    &--isError {
      font-size: 0.8rem;
      color: var(--red) !important;
    }
  }
  &:disabled{
    background:transparent !important;
    color: #333;

  }
}

.formGroup {
  text-align: left;
  padding: 0 !important;
}

.inputLabel{
  font-size: 15px;
}
